<template>
  <div class="container" @click="copyToClipboard()" :title="title">
    <slot></slot>
    <ciam-button class="clipboard-button">
      <ciam-icon v-if="showIcon" name="fa-copy"></ciam-icon>
    </ciam-button>
  </div>
</template>

<script>
import { Notification } from 'vue-notifyjs';

export default {
  name: 'ciam-clipboard',
  components: {},
  props: {
    payload: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    copyToClipboard() {
      const payload = this.payload ?? this.$scopedSlots.default()[0].elm.innerHTML;
      Notification.notify({
        type: 'info',
        message: this.$t('notifications.copiedToClipboard'),
      });
      navigator.clipboard.writeText(payload).catch((reason) => console.log('could not copy in clipboard', reason));
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.container {
  display: inline;
  cursor: pointer;
}

.clipboard-button {
  margin-left: 0.2rem;
}
</style>
