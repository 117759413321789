import PricingPlanService from './pricingPlanService';


export async function generatePlans() {
  let plansMockData = await PricingPlanService.get();
  return plansMockData.reduce((plan, item) => {
    item.levels.forEach((level) => {
      const entitlements = item.entitlements;
      // Check if plan is dedicated
      let dedicated = false;
      if (level.entitlements.infrastructure === 'dedicated') {
        dedicated = true;
      }
      // Create a plan item
      const planItem = {
        [level.id]: {
          type: item.name,
          image: require('@/assets/pricing-v2/' + item.name.toLowerCase().replace(/\s/g, '_') + '_256.png'),
          locations: level.locations,
          text: `${item.name} (${level.name})`,
          nbRealm: entitlements['max-realms'],
          nbMaxUsersPerRealm: entitlements['max-users'],
          dedicated: dedicated,
          sla: parseFloat(level.entitlements.sla.toString().replace(',', '.')),
          public: level.displayPrice,
          price: '0',
          supportLevel: level.name,
          name: level.id,
        },
      };
      Object.assign(plan, planItem);
    });

    return plan;
  }, {});
}


export default {
  /**
   *
   * @param {object} deployment
   * @returns {boolean} base on deployment links
   */
  hasCustomExtensions(deployment) {
    return deployment._links.extensions !== undefined;
  },

  /**
   *
   * @param {object} deployment
   * @returns {boolean} base on deployment links
   */
  hasThirdParties(deployment) {
    return deployment._links['third-parties'] !== undefined;
  },

  /**
   *
   * @param {object} deployment
   * @returns {boolean} base on deployment links
   */
  hasAuditLogs(deployment) {
    return deployment._links['audits'] !== undefined;
  },

  isFree(pricingPlan) {
    // as robust as in the backend 😭
    return pricingPlan.startsWith('LITTLE_LEMUR');
  },
};
