<template>
  <div class="uptime" v-if="monitorBadgeId">
    <div class="container-image">
        <img class="hidden-image" :src="`https://uptime.betterstack.com/status-badges/v1/monitor/${monitorBadgeId}.svg`">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    monitorBadgeId: {
      type: String,
      required: true,
    },
  }
}
</script>

<style>
.container-image {
    display: inline-block;
    overflow: hidden; 
    width: calc(100% - 62px);
}
.hidden-image {
    width: auto;
    clip-path: inset(0 0 0 62px);
    margin-left: -62px;
}
</style>