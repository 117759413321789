import {http, problemAwareHttp} from '@/http';
import { pathOr } from 'ramda';

export default class CustomExtensionService {
  /**
   * @typedef Extension
   * @property {String} fileId
   * @property {String} filename
   * @property {boolean} protected
   */

  /**
   *
   * @param {String} deployment_id - uuid
   * @param {File} file
   * @param {Maybe<Extension>} extension
   * @return {Promise<AxiosResponse<any>>}
   */
  static create(deployment_id, file, extension) {
    const formData = new FormData();
    formData.append('extension', file);

    extension.map(() => {
      // in replace mode, use the new file name
      formData.append('filename', file.name);
    });

    return problemAwareHttp[extension.isJust ? 'put' : 'post'](
      `/deployments/${deployment_id}/extensions/jars` + extension.map((ext) => `/${ext.fileId}`).getOrElse(''),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 300000
      }
    );
  }

  /**
   *
   * @param {String} deployment_id - uuid
   * @param {String} custom_extension_id - uuid
   * @return {Promise<AxiosResponse<any>>}
   */
  static remove(deployment_id, custom_extension_id) {
    return problemAwareHttp.delete(`/deployments/${deployment_id}/extensions/jars/${custom_extension_id}`, {});
  }

  /**
   *
   * @param {String} deployment_id - uuid
   * @return {Promise<Array<Object>>} - list of deployments inside a Promise
   */
  static list(deployment_id) {
    return problemAwareHttp
      .get(`/deployments/${deployment_id}/extensions/jars`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((res) => {
        const r = pathOr([], ['data', '_embedded', 'customExtensions'], res);
        return r;
      });
  }
}
