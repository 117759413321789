<template>
  <div class="badgeWrapper">
    <div class="version">
      <div v-if="statistics">
        <ciam-text> {{ statistics.version }}</ciam-text>
        <span v-if="canIUpgrade" class="tag is-warning is-light" title="New version">
          New version available :
          <u>
            <a class="ml-1" href="https://documentation.cloud-iam.com/references/upgrades.html#upgrade-plan"> Want to upgrade ?</a>
          </u>
        </span>
      </div>
      <div v-else>
        <ciam-text>...</ciam-text>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    statistics: {
      type: Object,
      required: true,
    },
    canIUpgrade: {
      type: Boolean,
      required: true
    }
  },
}
</script>