import { problemAwareHttp } from '@/http';


export default class PricingPlanService {

  planPromise = undefined;

  static async get() {

    if (this.planPromise === undefined) {
      this.planPromise = problemAwareHttp.get(`/plans`).then(resp => resp.data);
    }

    return this.planPromise;
  }
}
